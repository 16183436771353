body {
  margin: 0;
  font-family: "DM Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Source Code Pro";
}
